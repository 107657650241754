import * as React from "react"
import {graphql} from "gatsby"

import {Box, Flex} from "@chakra-ui/react"
import HeroShort from "../components/heroShort/hero";
import Stripes from "../components/common/stripes";
import Letter from "../components/common/letter";
import OfferCatBox from "../components/offer/offerCatBox/offerCatBox";
export default function html(props) {
	const page = props.page

	const offerList = [
		{
			animation: 'slide-right',
			href: '/oferta/dla-jednoosobowych-dzialalnosci-gospodarczych/',
			anchor: 'Jednoosobowe działalności gospodarcze',
			img: 'jednoosobowe_dzialanosci_gospodarcze.webp'
		},
		{
			animation: 'slide-up',
			href: '/oferta/dla-spolek-z-ograniczona-odpowiedzialnoscia/',
			anchor: 'Spółki<br />z o. o.',
			img: 'spolki_zoo.webp'
		},
		{
			animation: 'slide-left',
			href: '/oferta/dla-b2b/',
			anchor: 'Działalność<br />B2B',
			img: 'b2b.webp'
		},
	]

	return (
		<>
			<HeroShort page={page} target={'anchor'}/>
			<Box p={{ base: '20px 5% 20px 5%', md:'40px 0',xl:'5% 10% 5% 10%'}} position='relative' id={'anchor'}>
				<Letter letter={'O'} left={'-5%'} fontSize={'464px'} top={'-60px'}/>
				<Stripes bg1={'#f4f4f4'} bg2={'#EBEBEB'} bg3={'#f4f4f4'}  z0={'-1'} />
				<Flex
					maxW={'1280px'}
					m={'auto'}
					justifyContent={'space-evenly'}
					boxShadow='0 0 0 black'
					flexDirection = {{ base: 'column', md: 'row'}}
					flexWrap={'wrap'}
				>
					<OfferCatBox offer={offerList[0]} />
					<OfferCatBox offer={offerList[1]} />
					<OfferCatBox offer={offerList[2]} />
				</Flex>
			</Box>
		</>
	)
}

export const query = graphql`
  fragment PageDefaultInformation on WpPage {
	content
  }
`