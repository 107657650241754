import * as React from "react"
import {
    Box,
    Heading,
    SimpleGrid
} from "@chakra-ui/react";

import WhatWeDoModal from './modal'
import background from "/src/images/bg-dolar.webp"
import Stripes from "../../common/stripes";
import Letter from "../../common/letter";

export default function WhatWeDo({page}) {

    return (
        <Box position='relative' overflow='hidden' id="czym_sie_zajmujemy"
             p={{ base: '80px 5% 70px 5%', md:'100px 10% 90px 10%',xl:'130px 22% 140px 22%'}}
             zIndex='5'
             className={'section'} data-section={'czym_sie_zajmujemy'}>
            <Letter letter={'C'} right={'-6%'} fontSize={'424px'}/>
            <Stripes bg1={'#f4f4f4'} bg2={'#EBEBEB'} bg3={'#f4f4f4'} backgroundImage={background} />
            <Box zIndex='3' position='relative' maxW={'1120px'} m={'0 auto'}>
                <Heading as='h2' mb="50px" fontSize={{base: '34px', md: '42px'}} lineHeight={'48px'} maxW={'240px'}>Czym się zajmujemy?</Heading>
                <SimpleGrid columns={[1, null, 3]} spacing='30px' data-sal="slide-right" data-sal-duration="1000" data-sal-delay="300" data-sal-easing="ease">
                    <WhatWeDoModal name={page.homepageSections.czymSieZajmujemy.box1Title} content={page.homepageSections.czymSieZajmujemy.box1Content} icon={page.homepageSections.czymSieZajmujemy.box1Image.localFile.publicURL}/>
                    <WhatWeDoModal name={page.homepageSections.czymSieZajmujemy.box2Title} content={page.homepageSections.czymSieZajmujemy.box2Content} icon={page.homepageSections.czymSieZajmujemy.box2Image.localFile.publicURL}/>
                    <WhatWeDoModal name={page.homepageSections.czymSieZajmujemy.box3Title} content={page.homepageSections.czymSieZajmujemy.box3Content} icon={page.homepageSections.czymSieZajmujemy.box3Image.localFile.publicURL}/>
                </SimpleGrid>
                <Box data-sal="slide-up" data-sal-duration="1000" data-sal-delay="500" data-sal-easing="ease" my={[5,10]} dangerouslySetInnerHTML={{__html: page.homepageSections.czymSieZajmujemy.whatwedoContent}}></Box>
            </Box>
        </Box>
    )
}