import * as React from 'react'
import {Flex, Box} from "@chakra-ui/react";
import '/src/css/loader.css'

export default function FallbackLoading(){
    return(
        <Flex alignItems={'center'} justifyContent={'center'} opacity={.4}>
            <Box className="loading-ring" m={'20px'}>
                <Box></Box>
                <Box></Box>
                <Box></Box>
                <Box></Box>
            </Box>
        </Flex>
    )
}