import * as React from "react"
import {
    Box,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalCloseButton,
    AspectRatio,
    useDisclosure,
    Image, Text
} from "@chakra-ui/react";
import {StaticImage} from "gatsby-plugin-image";

export default function WhatWeDo(props) {

    const { isOpen, onOpen, onClose } = useDisclosure()

    return (
            <>
                <Modal isOpen={isOpen} onClose={onClose} bg='red' aria-modal={'true'}>
                    <ModalOverlay bg='#444444' />
                    <ModalContent borderRadius = '0' p={{ base: '10px 10px 50px 10px ',md: '38px'}} maxW='675px' w={'100%'} m={{base: '10px', md: '50px'}} position='relative'
                        _before = {{
                            content:'" "',
                            w: '18px',
                            height: '18px',
                            background : '#008F47',
                            display: 'block',
                            position: 'absolute',
                            right: '18px',
                            top:'0',
                        }}
                          _after = {{
                              content:'" "',
                              w: '18px',
                              height: '18px',
                              background : '#008F47',
                              display: 'block',
                              position: 'absolute',
                              right: '0',
                              top:'18px',
                          }}
                    >
                        <ModalHeader fontSize={{ base: '28px', md: '42px'}} fontWeight='bold' display={'flex'} flexDirection = {{ base: 'column', md: 'row'}} alignItems={{base:'flex-start', md: 'center'}} lineHeight={{ base: '32px', md: '57px'}}>
                            <Image src={props.icon} height={'53px'} alt='ikona' style={{
                                height:'53px',
                                display: 'inline-block',
                                marginRight: '30px',
                                marginBottom : {base:'20px', md: '0'}
                            }}/>
                            {props.name}
                        </ModalHeader>
                        <ModalCloseButton
                            bg='#9BCD66'
                            color='white'
                            size='lg'
                            borderRadius='0'
                            right={{base: '18px', md:'-22px'}}
                            top={{base: '18px', md: '-40px'}}
                            _focus = {{
                                outline: 'none'
                            }}
                            _hover = {{
                                bg : '#008F47',
                                color: 'white'
                            }}
                        />
                        <ModalBody
                            dangerouslySetInnerHTML={{__html: props.content}}
                            fontSize='14px'
                            fontWeight='normal'
                            fontFamily='Montserrat'
                            sx={{
                                "p" : { mb : '1em'},
                                "ul" : {p:'0', m:'0'},
                                "ul li" : { listStyleType: 'none'},
                                "li" : { p: '0', pl: '1em', mb : '1em'},
                                "li::before" :
                                    { content : '"■"',
                                        color: '#9BCD66',
                                        fontWeight: 'bold',
                                        display: 'inline-block',
                                        w: '1em',
                                        ml: '-1em',

                                    }
                            }}
                        >
                        </ModalBody>
                    </ModalContent>
                </Modal>
                <AspectRatio maxW='560px' ratio={{base: '2', md: '1'}} position='relative'>
                    <Box as='article'
                         onClick={onOpen}
                             p={{base: '15px',md:'35px'}}
                             w='100%'
                         cursor={'pointer'}
                             bg='#FFFFFF'
                             border='solid 6px #E1E4E6'
                             position='relative'
                             rounded='md'
                             sx = {{
                                 '&:hover>div' : {
                                    pr:'0'
                                 }
                             }}
                    >
                        <Box position = 'absolute' top = {{base: '15px',md:'35px'}} left = {{base: '15px',md:'35px'}}>
                        <Image src={props.icon} alt='icon' height={'58px'} width={'auto'} style={{
                            height: '58px',
                            width : 'auto'
                        }}
                        />
                        </Box>
                        <Text fontSize={'15px'} fontWeight='bold' position='absolute' top='50%' left={{base: '15px',md:'35px'}} maxWidth={'80%'} >{props.name}</Text>
                        <Box position='absolute' bottom='4' right='5' transition = 'all 0.3s' p='0 25px 10px 0' >
                            <StaticImage src='images/arrow.svg' alt='Arrow right'/>
                        </Box>
                    </Box>
                </AspectRatio>
            </>
    )
}