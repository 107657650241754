import {Box, Flex, Heading, Img, ListItem, UnorderedList} from "@chakra-ui/react";
import * as React from "react";
import arrow from "/src/images/arrow.svg";
import JdgPicture from "./jdgPicture";
import ZooPicture from "./zooPicture";
import kropkiSVG from './images/kropki.svg'
import FallbackLoading from "../../common/fallbackLoading";
import B2bPicture from "./b2bPicture";

export default function FinancialAdvantages(props){
    const scroll = () => {
        const section = document.querySelector( '#koszt_obslugi' );
        section.scrollIntoView( { behavior: 'smooth', block: 'start' } );
    };

    const Letter = React.lazy(() => import( "../../common/letter"));

    const page = props.page
    let img = '';
    if(page.id === 'cG9zdDo1Mw=='){
        img = <JdgPicture />
    } else if(page.id === 'cG9zdDo1NDA=') {
        img = <B2bPicture />
    } else {
        img = <ZooPicture />
    }

    const kropki = <Box position='absolute' zIndex='1' top={'28%'} right = {{base:'0',md:'50px'}}><Img src={kropkiSVG} style={{ width: '110%'}} alt={'Kropki'} data-sal="slide-right" data-sal-duration="1000" data-sal-delay="300" data-sal-easing="ease"/></Box>

    return (
        <Box display='flex' flexWrap={{base : 'wrap', lg: 'nowrap'}} p={{ base: '5% 5% 0 5%',lg:'5% 0% 0 0%'}} id='firstSection'
             maxW={'1120px'} margin={'0 auto'}
             
        >
            <React.Suspense fallback={<FallbackLoading />}><Letter letter={'U'} fontSize={'464px'} bottom={'20%'} left={'-5%'} color={'#E4F3EB'}/></React.Suspense>
            <Flex w={{base : '100%', lg: '60%'}}
                 zIndex={'2'}
                 order={{base: '2', lg: '1'}}
                 mt={{base : '30px', lg: '0'}}
                 position={'relative'}
                 alignItems={'stretch'}
            >
                {kropki}
                {img}
            </Flex>

            <Box w={{base : '100%', lg: '40%'}} zIndex={'2'} order={{base: '1', lg: '2'}}>
                <Heading as='h2' mb='43px' fontSize={{base: '34px', lg: '42px'}} fontFamily="'essonnes-headline', serif" fontWeight='bold'>Dlaczego warto z nami współpracować?</Heading>
                <UnorderedList listStyleType='none' ml={'2em'} maxW={{base: '100%', lg: '80%'}} className={'boxed'}>
                    <ListItem data-sal="slide-left" data-sal-duration="1000" data-sal-delay="300" data-sal-easing="ease">Zajmujesz się swoim biznesem, reszta jest w&nbsp;rękach ekspertów</ListItem>
                    <ListItem data-sal="slide-left" data-sal-duration="1000" data-sal-delay="350" data-sal-easing="ease">Kompleksowa obsługa księgowa i&nbsp;kadrowa</ListItem>
                    <ListItem data-sal="slide-left" data-sal-duration="1000" data-sal-delay="400" data-sal-easing="ease">Masz stały wgląd w&nbsp;sytuację finansową i&nbsp;kontrolujesz płatności</ListItem>
                    <ListItem data-sal="slide-left" data-sal-duration="1000" data-sal-delay="450" data-sal-easing="ease">Pomożemy Ci uzyskać dotacje i&nbsp;inne środki pomocowe</ListItem>
                </UnorderedList>
                <Box as='button' bg='#008F47'
                      borderRadius='0'
                      color='white'
                      w='100%'
                      p='0 60px 0 30px'
                      h='auto'
                      fontSize='13px'
                      fontWeight='bold'
                      textTransform='uppercase'
                      letterSpacing = '2px'
                      maxW='360px'
                      lineHeight={'1'}
                      height={'55px'}
                      position = 'relative'
                      justifyContent='flex-start'
                      alignItems={'center'}
                      textAlign='left'
                      onClick = {scroll}
                      data-sal="slide-up" data-sal-duration="1000" data-sal-delay="500" data-sal-easing="ease"
                      m={{base:'0 auto', lg:'0 0 120px 0'}}
                      display={{base: 'block', lg: 'flex'}}
                      _active={{
                          textDecoration: 'none',
                          bg: '#008F47'
                      }}
                      _focus={{
                          textDecoration: 'none',
                          bg: '#008F47'
                      }}
                      _hover={{
                          textDecoration: 'none',
                          bg: '#008F47'
                      }}
                      sx = {{
                          '&:hover>div' : {
                              pr:'0'
                          }
                      }}
                >Oblicz koszt obsługi
                    <Box as={'span'} position='absolute'
                         bottom='20px'
                         right='15px'
                         transition = 'all 0.3s'
                         p='0 20px 0 0'
                    >
                        <Img src={arrow} alt='Arrow right' style={{ width: '40px'}}/>
                    </Box>
                </Box>
            </Box>
        </Box>
    )
}
