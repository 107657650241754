import * as React from "react"
import {graphql} from "gatsby"
import PageBreadcrump from "../components/page/page.breadcrump";
import { Box, Heading } from "@chakra-ui/react"
import HeroShort from "../components/heroShort/hero";

export default function html(props) {
	const page = props.page

	return (
		<>
			<HeroShort page={page}/>
			<Box p={{ base: '20px 5% 20px 5%', md:'40px 10% 40px 10%',lg:'0 15% 50px 15%'}}>
				<PageBreadcrump node={page}/>
				<Heading as='p'>{page.title}</Heading>
				<div dangerouslySetInnerHTML={{__html: page.content}} className={page.template.templateName.toLowerCase()} />
			</Box>
		</>
	)
}

export const query = graphql`
  fragment PageDefaultInformation on WpPage {
	content
  }
`