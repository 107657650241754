import * as React from "react"
import {graphql} from "gatsby"
import {Box, Heading} from "@chakra-ui/react"
import Stripes from "../components/common/stripes"
import CareerJobs from "../components/career/jobs";
import HeroShort from "../components/heroShort/hero";
import Letter from "../components/common/letter";
import Career from "../components/career/career/career";

import dolar from "/src/images/bg-dolar.webp";

export default function html(props) {
	const page = props.page

	return (
		<>
			<HeroShort page={page} target={'firstSection'}/>
			<Career />
			<Box p={{ base: '20px 5% 20px 5%', md:'40px 10% 40px 10%',lg:'0 15% 50px 15%'}}
				 position={'relative'} overflow={'hidden'} id={"nasze_oferty"}
			>
				<Letter letter={'0'}  top={"30%"}  right={"-5%"} fontSize={"424px"}/>
				<Stripes z0={'-1'} z2={'-1'} bg1={'#f4f4f4'} backgroundImage = {dolar} bg2={'#EBEBEB'} bg3={'#f4f4f4'}/>
				<Heading zIndex={'2'} as='h2' width={'100%'} textAlign={'center'} fontSize={{base: '34px', md: '42px'}}
						 pt={{base: '60px', md:'140px'}}
						 mb='120px' fontFamily="'essonnes-headline', serif" fontWeight='bold'>Oferty pracy</Heading>
				<CareerJobs page={props.page} />
			</Box>
		</>
	)
}

export const query = graphql`
  fragment PageDefaultInformation on WpPage {
	content
  }
`