import * as React from "react"
import {graphql} from "gatsby"

import Hero from '../components/home/hero/hero'
import FallbackLoading from "../components/common/fallbackLoading";
import WhatWeDo from "../components/home/whatWeDo/whatWeDo";
import {Box} from "@chakra-ui/react";

export default function HomePage({page}) {
    const AboutUs = React.lazy(() => import("../components/home/aboutUs/aboutUs"));

    const OurGoals = React.lazy(() => import("../components/home/ourGoals/ourGoals"));
    const VertMenu = React.lazy(() => import("../components/home/vertMenu/verMenu"));
    return (
        <>
            <Hero page={page}/>
            <Box position={'relative'} pt={{base: 0, md: '800px'}}>
                <React.Suspense fallback={<FallbackLoading />}><AboutUs page={page}/></React.Suspense>
                <WhatWeDo page={page}/>
                <React.Suspense fallback={<FallbackLoading />}><OurGoals page={page}/></React.Suspense>
                <React.Suspense fallback={<FallbackLoading />}><VertMenu /></React.Suspense>
            </Box>
        </>
    )
}

export const query = graphql`
  fragment HomepageInformation on WpPage {
    content
      homepageSections {
          hero {
              image {
                  id
                  sourceUrl
                  localFile {
                      childImageSharp {
                          id
                          gatsbyImageData(
                              placeholder: NONE
                              width:455
                              formats: [AUTO, WEBP, AVIF]
                          )
                      }
                  }
              }
              h1
              description
          }
          oNas{
              image {
                  id
                  sourceUrl
                  localFile {
                      childImageSharp {
                          id
                          gatsbyImageData(
                              placeholder: NONE
                              width:559
                              formats: [AUTO, WEBP, AVIF]
                          )
                      }
                  }
              }
              header
              description
          }
          czymSieZajmujemy {
              box1Content
              box1Image {
                  id
                  localFile {
                      publicURL
                  }
              }
              box1Title
              box2Content
              box2Image {
                  id
                  localFile {
                      publicURL
                  }
              }
              box2Title
              box3Content
              box3Image {
                  id
                  localFile {
                      publicURL
                  }
              }
              box3Title
              whatwedoContent
          }    
          naszeCele{
              image {
                  id
                  sourceUrl
                  localFile {
                      childImageSharp {
                          id
                          gatsbyImageData(
                              placeholder: NONE
                              width: 466
                              formats: [AUTO, WEBP, AVIF]
                          )
                      }
                  }
              }
              header
              description
          }
      }
      ...ContactPageDefaultInformation
  }
`

