import {Box, Button, Flex, Heading, Img} from "@chakra-ui/react";
import Stripes from "../../common/stripes";
import Letter from "../../common/letter";
import kropki from "./images/mega_kropki.svg";
import arrowDown from "/src/images/arrow_down.svg";
import * as React from "react";
import {StaticImage} from "gatsby-plugin-image";

export default function Career(){

    const scroll = () => {
        const section = document.querySelector( '#nasze_oferty' );
        section.scrollIntoView( { behavior: 'smooth', block: 'start' } );
    };

    return (
        <Box display='flex'
             flexWrap={{base : 'wrap', md: 'nowrap'}}
             p={{ base: '20px 5% 20px 5%', md:'40px 10% 40px 10%',lg:'5% 15% 0 15%'}}
             id='firstSection'
             position={'relative'}
        >
            <Stripes bg2={'#008f471a'} />
            <Letter letter={'K'} left={'-5%'} top = {"20%"} fontSize = {'464px'}/>
            <Flex maxW={'1120px'} m={'auto'} flexDirection={{base: 'column', md: 'row'}}>
                <Box w={{base : '100%', md: '60%'}}
                     zIndex={'2'}
                     mt={{base : '30px', md: '0'}}
                     position={'relative'}
                >
                    <StaticImage src={'./images/megafon@2x.png'} alt={'Kariera'} placeholder={'none'}/>
                    <Img src={kropki} alt={'Kropki'} position={'absolute'} zIndex={1} top={'10%'} right={{base:'0',md:'100px'}} data-sal="slide-right" data-sal-duration="1000" data-sal-delay="300" data-sal-easing="ease"/>
                </Box>

                <Box w={{base : '100%', md: '40%'}} zIndex={'2'} pt={{base:'0',md:'40px'}}>
                    <Heading as='h2' mb='30px' fontSize={{base: '34px', md: '42px'}} fontFamily="'essonnes-headline', sans-serif" fontWeight='bold'
                             maxW={{base : '100%', md: '80%'}}
                    >
                        Kariera w biurze rachunkowym
                    </Heading>
                    <Box maxW={{base : '100%', md: '360px'}} mb={'60px'} fontSize={'15px'} lineHeight={'26px'} fontWeight={'bold'}
                         data-sal="slide-left" data-sal-duration="1000" data-sal-delay="300" data-sal-easing="ease">
                        Dołącz do Cyrek Finance i rozwijaj z nami swoją karierę!</Box>
                    <Box maxW={{base : '100%', md: '360px'}} mb={'66px'} fontSize={'14px'} lineHeight={'28px'}
                         data-sal="slide-left" data-sal-duration="1000" data-sal-delay="400" data-sal-easing="ease">
                        Nadrzędnym celem jest dla nas zbudowanie miejsca pracy, w którym każdy niezależnie od umiejętności z jakimi do nas przychodzi odnajdzie ludzi wyznających zbieżne mu wartości przez co będzie mógł realizować się zawodowo oraz dążyć do osiągnięcia wspólnego celu - sukcesu.</Box>
                    <Button
                        size="xl"
                        fontWeight="bold"
                        position="relative"
                        transition = 'all 0.3s'
                        letterSpacing = '5px'
                        lineHeight = '40px'
                        display = 'inline-block'
                        pr = '50px'
                        ml={'0'}
                        pl={'0'}
                        background={'transparent'}
                        mb={{base: '20px',md:'180px'}}
                        textTransform = 'uppercase'
                        _hover={{
                            textDecoration: 'none',
                        }}
                        onClick = {scroll}
                        sx={{ '&:hover img' : { top: '30px' } }}
                        data-sal="slide-up" data-sal-duration="1000" data-sal-delay="300" data-sal-easing="ease"
                    >
                        Sprawdź oferty
                        <Img style={{ width: '14px', height:'31px', position: 'absolute', top:'4px', right:'0', transition : 'all 0.3s'}} alt={'arrow_down'} src={arrowDown}/>
                    </Button>
                </Box>
            </Flex>
        </Box>
    )
}