import * as React from "react"

import 'swiper/css';
import 'swiper/css/navigation';
import '/src/css/common.scss'
import '/src/css/lists.scss'

import HeroShort from "/src/views/components/heroShort/hero";
import Stripes from "/src/views/components/common/stripes";
import FinancialAdvantages from "/src/views/components/offer/financialAdvantages/financialAdvantages";
import FallbackLoading from "../components/common/fallbackLoading";
import {Box} from "@chakra-ui/react";
import PageBreadcrump from "../components/page/page.breadcrump";
import {graphql} from "gatsby";


export default function html(props) {

	const page = props.page

	const FinancialServices = React.lazy(() => import("/src/views/components/offer/financialServices/financialServices"));
	const WeDoMore = React.lazy(() => import( "../components/offer/weDoMore/weDoMore"));
	const Recommendations = React.lazy(() => import( "/src/views/components/offer/recommendations/recommendations"));
	const ClientMap = React.lazy(() => import( "../components/offer/clientMap/clientMap"));
	const Offer = React.lazy(() => import( "../components/offer/offer/offer"));
	const CostCalculator = React.lazy(() => import( "/src/views/components/offer/costCalculator/costCalculator"));

	return (
		<>
			<HeroShort page={page} subtitle='Usługi rachunkowe, księgowe i kadrowe' />
			<Stripes bg2={'#008f471a'} z0={'0'}/>
			<Box pl={'30px'} maxW='1280px' m={'30px auto'} fontSize={'11px'} color={'#32353833'} fontWeight={'semibold'} zIndex={'10'}>
			<PageBreadcrump node={page}/>
			</Box>
			<FinancialAdvantages page={page}/>
			<React.Suspense fallback={<FallbackLoading />}><FinancialServices /></React.Suspense>
			<React.Suspense fallback={<FallbackLoading />}><WeDoMore /></React.Suspense>
			<React.Suspense fallback={<FallbackLoading />}><Recommendations /></React.Suspense>
			<React.Suspense fallback={<FallbackLoading />}><ClientMap /></React.Suspense>
			<React.Suspense fallback={<FallbackLoading />}><Offer /></React.Suspense>
			<React.Suspense fallback={<FallbackLoading />}><CostCalculator page={page}/></React.Suspense>
		</>
	)
}

export const query = graphql`
  fragment SingleOfferInformation on WpPage {
	template {
		... on WpTemplate_OfferSingle {
			packageOptions {
			  package {
				packageName
				packagePrice
				packageDescription
				packageContent
				packageLoved
			  }
			}
		}
	}
  }
`
