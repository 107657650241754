import * as React from "react"
import {graphql, useStaticQuery} from "gatsby"
import JobBox from "./jobBox";
import {GridItem, Grid} from "@chakra-ui/react"

export default function CareerJobs(props) {

	const data = useStaticQuery(graphql`
        query CareerJobs_query {
          jobs: allWpJob(sort: {date: DESC}) {
            nodes {
              id  
              title
              slug
              uri
              language{
                code
              }
            }
          }
        }
	`)

	const jobs = data.jobs.nodes.filter(job => job.language.code === props.page.language.code)

	return (
		<>
            <Grid templateColumns={{base:'1fr',sm: '1fr 1fr', md:'1fr 1fr', xl:'1fr 1fr 1fr'}} gap={5} maxW={'960px'} m={'auto'} mb={'100px'} w={'100%'}>
				{jobs.map((node,i) => (
					<GridItem key={node.id} borderWidth='6px' className={'forcedRectangle'}  bg='white' fontSize='15px' fontWeight='bold' textTransform='uppercase' position='relative' data-sal="slide-right" data-sal-duration="1000" data-sal-delay="300" data-sal-easing="ease">
						<JobBox node={node} i={i} position={'relative'} />
					</GridItem>
				))}
            </Grid>
		</>
	)



}




