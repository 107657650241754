import * as React from "react"
import Layout from "../views/layouts/layout"
import PageDefault from "../views/templates/template.page.default"
import PageHome from "../views/templates/template.page.home"
import PageBlog from "../views/templates/template.page.blog"
import PageContact from "../views/templates/template.page.contact"
import PageOffer from "../views/templates/template.page.offer"
import PageCareer from "../views/templates/template.page.career"
import PageOfferSingle from "../views/templates/template.page.offer-single"

import {graphql} from "gatsby";


export default function PageController(props) {
    const page = props.data.page

    return (
        <Layout node={page}>
            {setTemplate(page)}
        </Layout>
    )
}

const setTemplate = (page) => {
    switch(page.template.templateName) {
        case 'Homepage':
            return <PageHome page={page}/>;
        case 'Blog':
            return <PageBlog page={page}/>;
        case 'Career':
            return <PageCareer page={page}/>;
        case 'Contact':
            return <PageContact page={page}/>;
        case 'Offer':
            return <PageOffer page={page}/>;
        case 'Offer Single':
            return <PageOfferSingle page={page}/>;
        default:
            return <PageDefault page={page}/>;
    }
}

export const pageQuery = (graphql`
  query PageById(
    $id: String!
  ) {  
    page: wpPage(id: { eq: $id }) {
      id
      title
      template {
        templateName
      }
        uri
      language{
        code
      }
      seo {
        canonical
        metaDescription
        metaTitle
        nofollow
        noindex
        h1
      }
      og {
        title
        description
        image {
          localFile {
            relativePath
            publicURL
              childImageSharp {
                  id
                  gatsbyImageData(
                      placeholder: NONE
                      quality: 70
                      formats: [AUTO, WEBP, AVIF]
                      layout: FULL_WIDTH
                  )
              }
          }
        }
      }
      
      ...PageDefaultInformation
      ...HomepageInformation
      ...SingleOfferInformation
    }
  }
`)