import React from 'react';
import { AspectRatio, Box, Heading, Img } from '@chakra-ui/react'
import ContactForm from "../components/form/contactForm";
import HeroShort from "../components/heroShort/hero";
import Stripes from "../components/common/stripes";
import Letter from "../components/common/letter";
import arrowDown from "../../images/arrow_down.svg";
import {graphql} from "gatsby";

export default function html(props) {
	const page = props.page

	const scroll = () => {
		const section = document.querySelector( '#jak_dojechac' );
		section.scrollIntoView( { behavior: 'smooth', block: 'start' } );
	};

	return (
		<>
			<HeroShort page={page} target={'first'}/>
			<Box id={'first'}
				p={{ base: '5%', md:'120px 5% 40px 5%',xl:'120px 15% 50px 15%'}}
				position={'relative'}
				 maxW={'1920px'}
				 m={'auto'}
			>
				<Letter letter={'K'} fontSize={'464px'} color={'#E4F3EB'}/>
				<Stripes bg1={'#FFFFFF'} bg2={'#008F471A'} bg3={'#FFFFFF'}  z0={'0'} z1={'-1'} z2={'-1'} z3={'-1'} />
				<Box display='flex' flexWrap={{base:'wrap',md:'nowrap'}}>
					<Box w={{base:'100%',md:'50%'}} zIndex={'2'} pl={{base:'0', md: '5%', xl: '10%'}} data-sal="slide-right" data-sal-duration="1000" data-sal-delay="200" data-sal-easing="ease">

						<Heading as={'h2'} mb={'20px'} fontSize={'15px'} fontWeight={'bold'} fontFamily={'Montserrat'} dangerouslySetInnerHTML={{__html: page.contactSections.daneKontaktowe.header}} />
						
						<Box mb={'28px'} dangerouslySetInnerHTML={{__html: page.contactSections.daneKontaktowe.addres}} />
						<Box mb={'30px'} dangerouslySetInnerHTML={{__html: page.contactSections.daneKontaktowe.nipRegonKrs}} />
						<Box mb={'30px'} dangerouslySetInnerHTML={{__html: page.contactSections.daneKontaktowe.eMail}} />
						<Box as={'p'} mb={'30px'} dangerouslySetInnerHTML={{__html: page.contactSections.daneKontaktowe.telefon}}/>
						<Box as={'p'} mb={'80px'} dangerouslySetInnerHTML={{__html: page.contactSections.daneKontaktowe.godzinyOtwarcia}}/>

						

						<Box mb={'80px'} w={'auto'} onClick={scroll} textTransform={'uppercase'} display={'inline-block'}
							 letterSpacing={'wide'} fontWeight={'bold'} cursor={'pointer'}
							 position = {{base: 'absolute', md: 'relative'}}
							 top = {{base: '90px', md: 'auto'}}
							 left = {{base: '60%', md: 'auto'}}
							 fontSize={'14px'}
							 sx={{ '&:hover img' : { marginTop: '50px !important' } }}
						>
							zobacz<br/>
							jak dojechać
							<Img style={{ width: '14px', height: '31px', marginTop:'30px', transition : 'all 0.3s'}} src={arrowDown} alt={'arrow_down'} />
						</Box>
					</Box>
					<Box w={{base:'100%',md:'50%'}} zIndex={'2'} pl={{base:'0',md:'20px'}}>
						<Heading as={'h2'} fontSize={{base: '34px', md: '42px'}} lineHeight={'48px'}>Wypełnij formularz</Heading>
						<ContactForm />
					</Box>
				</Box>
			</Box>
			<Box position='relative' zIndex={1}  id={'jak_dojechac'}>
				<Box display='flex' p={'0'}
					 justifyContent={'space-between'}
					 flexWrap={{base:'wrap',lg:'nowrap'}}
					 bg={'#EBEBEB'}
				>
					<Box w={{base: '100%', lg: 'calc(50% - 205px)'}} zIndex={'1'}>
						<Box float={{ base: 'none', lg: 'right'}} mr={{base: '0', lg:'20%'}} ml={{base: '5%', lg: '0'}}>
							<Heading as='h2'
									 maxW={'240px'}
									 mt={{base:'20px', lg: '140px'}}
									 mb={{base: '30px',lg: '0'}}
									 lineHeight={'48px'}
									 fontSize={{base: '34px', lg: '42px'}}
									 fontFamily="'essonnes-headline', sans-serif" fontWeight='bold'>
								Dojazd<br/> do nas
							</Heading>
						</Box>
					</Box>
					<Box w={{base: '100%', lg: 'calc(50% + 205px)'}} zIndex={{ base: '1', lg: '0'}}>
						<AspectRatio ratio={{ base: 2,lg: 8/5}}>
							<iframe title={'Gmap'} src="https://snazzymaps.com/embed/420782"></iframe>
						</AspectRatio>
					</Box>
				</Box>
			</Box>

		</>

	)
}

export const query = graphql`
	fragment ContactPageDefaultInformation on WpPage {
		contactSections {
			daneKontaktowe {
				telefon
				nipRegonKrs
				header
				eMail
				addres
				godzinyOtwarcia
			}
		}
	}
`