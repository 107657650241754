import * as React from "react";
import {AspectRatio, Box, Heading, Image, LinkBox, LinkOverlay} from "@chakra-ui/react";
import arrow from "/src/images/arrow.svg";
import {stripHtml} from "string-strip-html";

export default function OfferCatBox(props){

    let data = props.offer;

    let img = require('./images/'+data.img).default;

    return(
        <>
            <AspectRatio
                ratio='1' w='100%'
                bgImage = {img}
                bgColor = 'white'
                bgSize = 'contain'
                bgPosition = 'bottom right'
                bgRepeat= 'no-repeat'
                borderWidth='6px'
                borderColor='#E1E4E6'
                zIndex='2'
                m={'5px'}
                position='relative'
                maxW= {{base: 'none', md: 'calc(33% - 10px)'}}
                maxH= {{base: 'none', md: '359px'}}
                className={'animated'}
                boxShadow={'0 100px 99px #00000029'}
                data-sal={data.animation} data-sal-duration="1000" data-sal-delay="0" data-sal-easing="ease"
            >
                <LinkBox
                      position = "absolute"
                      top = '0'
                      bottom = '0'
                      left = '0'
                      right = '0'
                      _hover = {{
                          textDecoration: 'none'
                      }}
                      sx={{ '&:hover .arrow' : { left: '60px' } }}
                      title={stripHtml(data.anchor).result}
                >
                    <Heading as="h2"
                             fontFamily = "'essonnes-headline', serif"
                             fontSize ={{base: '7vw', md: 'clamp(22px, 3vw, 32px)'}}
                             position={'absolute'}
                             top= {{base: '22px', md: '20px'}}
                             left= {{base: '22px', md: '20px'}}
                             maxW={'80%'}
                             m={'0'}
                            dangerouslySetInnerHTML={{__html: data.anchor}}
                    />
                    <LinkOverlay href={data.href}>
                        <Box as={'span'} position='absolute' className='arrow' transition = 'all 0.3s'
                             bottom = '40px'
                             left = '40px'>
                            <Image src={arrow} alt='Arrow right' width={'42px'} loading={'lazy'}/>
                        </Box>
                    </LinkOverlay>
                </LinkBox>
            </AspectRatio>

        </>
    )
}