import * as React from "react"
import {Box, Flex, Img} from "@chakra-ui/react";
import background from "/src/images/background.webp"
import headBoxes from "./images/head_boxes.svg";
import arrowDown from "/src/images/arrow_down.svg";
import Letter from "../../common/letter";
import {GatsbyImage, getImage} from "gatsby-plugin-image";

export default function Hero({page}) {
    const scroll = () => {
        const section = document.querySelector( '#kim_jestesmy' );
        section.scrollIntoView( { behavior: 'smooth', block: 'start' } );
    };

    const image = page.homepageSections.hero.image;
    const img = image ? getImage(image.localFile) : null

    return (
        <Box position={{base: 'relative', md: 'absolute'}} left={'0'} right={'0'} maxH={{ base: 'none', md: '600px'}} id="top"
             className={'section'} data-section={'hero'}
        >
            <Letter letter={'F'} opacity={'0.23'} top={'5%'} right={'0'} color={'#008F47'} fontSize={'464px'}/>
            <Flex
                position='absolute;'
                height='100%'
                width='100%'
                justifyContent='space-between'
                top='0'
                right='0'
                left='0'
                bottom='0'
                z-index='-1'
            >
                <Box minHeight="800px" bg='#005B2E' backgroundImage={headBoxes}  backgroundPosition='left top' width='100%'></Box>
                <Box minHeight="800px" width='100%' maxW = '410px' backgroundColor={'#0e9752'} backgroundImage={background} backgroundSize='cover' position='relative'
                     _before={{
                         content: '" "',
                         backgroundColor: "#008F4700",
                         position: "absolute",
                         height: "100%",
                         width: "100%",
                         opacity: "0.85",
                         left: '0',
                         top: '0'
                     }}
                ></Box>
                <Box minHeight="800px" bg='#005B2E' backgroundImage={headBoxes} backgroundPosition='right bottom' width='100%'></Box>
            </Flex>
                <Box maxW={'1920px'} m={'auto'} padding={{ base: '30% 5% 15% 5%', md:'20% 10% 15% 10%',lg:'180px 25% 15% 25%'}} zIndex='3' position='relative' color='white'
                     id = 'hero'>
                    <GatsbyImage image={img} alt='Finanace Header' style = {{ marginLeft: '5px' }} />
                    <Box fontSize='38px' mb='50px' mt={'55px'} as={'h1'}
                         lineHeight='1.3' fontFamily="'essonnes-headline', serif" fontWeight='bold'
                         dangerouslySetInnerHTML={{__html: page.homepageSections.hero.h1}}
                    />
                    <Box mb={[8,12]} w='100%' maxW='527px' lineHeight='28px' fontSize={'14px'}>
                        {page.homepageSections.hero.description}
                    </Box>

                    <Box
                        size="xl"
                        fontWeight="bold"
                        position="relative"
                        transition = 'all 0.3s'
                        letterSpacing = '5px'
                        lineHeight = '40px'
                        display = 'inline-block'
                        fontFamily = 'Montserrat'
                        textTransform = 'uppercase'
                        cursor={'pointer'}
                        pr = '50px'
                        onClick = {scroll}
                        _hover={{
                            textDecoration: 'none',
                        }}
                        sx={{ '&:hover img' : { top: '30px' } }}
                    >
                        Sprawdź kim jesteśmy
                        <Img alt={'arrow_down'} w='14px' h='31px' pos='absolute' top='4px' right='0' src={arrowDown} transition = 'all 0.3s'/>
                    </Box>
                </Box>
        </Box>

    )
}