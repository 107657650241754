import * as React from "react"
import {Link} from "gatsby";
import {Box, Image, Img} from '@chakra-ui/react'
import arrow from '/src/images/arrow.svg'
import send from '/src/images/send.svg'

export default function JobBox(props) {
    const job = props.node
    return (
        <Link to={job.uri} >
            <Box
                color='black'
                fontWeight='semibold'
                letterSpacing='wide'
                minH='100px'
                height={'100%'}
                p={'30px'}
                position={'relative'}
                transition = 'all 0.3s'
                sx={{ '&:hover .arrow' : { right: '15px' } }}
            >
                <Img src={send} alt='Send' pb={'36px'} />
                {job.title}
                <Box position='absolute' className='arrow' transition = 'all 0.3s'
                     bottom = {{base: '10px',md:'37px'}}
                     right = '37px'>
                    <Image src={arrow} alt='Arrow right' width={'42px'}/>
                </Box>
            </Box>
        </Link>
    )
}