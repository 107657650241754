import {Box, ButtonGroup, Img, VStack, Flex} from "@chakra-ui/react";
import {ErrorMessage, Field, Formik} from "formik";
import axios from "axios"
import {
    InputControl,
    SubmitButton,
    TextareaControl
} from "formik-chakra-ui";
import * as React from "react";
import * as Yup from "yup";
import arrow from "../../../images/arrow.svg";


const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

const onSubmit = (values,{ setSubmitting, setStatus, resetForm }) => {

    sleep(300).then(() => {
        setStatus();
        const formData = new FormData();
        for (const [key, value] of Object.entries(values)) {
            formData.append(key, value);
        }

        formData.append('template', 'contact');
        formData.append('subject', 'Zapytanie ze strony cyrekfinance.com');
        formData.append('mailerFrom', 'biuro@cyrekfinance.com');
        formData.append('mailerTo', process.env.GATSBY_EMAIL_ADDRESS);
        formData.append('mailerFromName', 'Strona kontaktowa');

        axios({
            method: "POST",
            url: process.env.GATSBY_API_URL + '?module=mailer',
            data: formData,
            header: {
                'Content-Type': 'multipart/form-data'
            },
        }).then(res => {
            resetForm();
            setSubmitting(false);
            document.getElementById('contactForm').style.display = 'none';
            setStatus(<Box background={'#008F47'} p={'10px'} color={'#FFF'} mt={'40px'}>Dziękujemy za przesłaną wiadomość.</Box>);
        }).catch(err => {
            alert('error!');
        });

    });
};

const initialValues = {
    fullName: "",
    email: "",
    phone: "",
    message: "",
    agreement: false
};

const validationSchema = Yup.object({
    fullName: Yup.string().required('Imię i nazwisko są wymagane'),
    email: Yup.string().email('E-mail jest niepoprawny.').required('E-mail jest wymagany.'),
    phone: Yup.number().typeError('Dopuszczamy tylko cyfry').required('Prosimy podać swój numer telefonu'),
    message: Yup.string().required('Wiadomość nie może byc pusta'),
    agreement: Yup.boolean().oneOf([true],'Zgoda jest wymagana'),
});

const ContactForm = () => {

    return (
            <Formik
                initialValues={initialValues}
                onSubmit={onSubmit}
                validationSchema={validationSchema}
            >
                {({ handleSubmit, values,setFieldValue, errors, status }) => (
                    <Box
                        as="form"
                        onSubmit={handleSubmit}
                    >
                        {status}
                        <VStack spacing={6} align="flex-start"
                            p={'20px 0'}
                            data-sal="slide-left" data-sal-duration="1000" data-sal-delay="300" data-sal-easing="ease"
                            m="10px 0"
                            maxW={'455px'}
                            id={'contactForm'}
                            sx={{
                                '.chakra-form__required-indicator' : {display: 'none'},
                                '.chakra-form__error-message' : { fontSize: '11px', mt :'0px'},
                                '.abs label' : { position: 'absolute', top: '8px', pl: '10px'},
                                '.abs label::before' : {
                                    content: "'■'",
                                    color : '#9BCD66',
                                    fontWeight : 'bold',
                                    mr: '10px',
                                    ml: '-10px',
                                    display: 'inline',
                                    position: 'absolute',
                                    top: '3px',
                                    fontSize: '7px'
                                },
                                'label.isFilled, .abs label[data-focus], .abs label[data-invalid]' : { top: '-20px'},
                                'input[aria-invalid="true"]' : { border: '0', borderRadius: '0', borderBottom: '2px solid red', boxShadow: 'none'},
                                '.abs label[data-invalid] input, #message[data-invalid]' : { border: '0', borderBottom: '2px solid red', boxShadow: 'none'},
                                'textarea:active, textarea:hover, textarea:focus, textarea:focus-visible, input:active, input:hover, input:focus, input:focus-visible' : {border: '0', borderRadius: '0', borderBottom: '2px solid #008F47', boxShadow: 'none'},
                                'textarea, input, input[type=checkbox]' : {border: '0', borderRadius: '0'},
                                'textarea, input' : {borderBottom: '1px solid #3235381A'},
                                'a' : { fontWeight: 'bold'}
                            }}>
                            <InputControl name="fullName" label="Imię i nazwisko" className={'abs'} id={'fullNameGroup'} transition = 'all 0.3s'
                                          onBlur = {(event) => {
                                              if (event.target.defaultValue.trim().length > 0 ){
                                                  document.getElementById('fullNameGroup-label').classList.add('isFilled');
                                              } else {
                                                  document.getElementById('fullNameGroup-label').classList.remove('isFilled');
                                              }
                                          }}
                            />
                            <InputControl name="email" label="Twój adres e-mail" className={'abs'} id={'emailGroup'} transition = 'all 0.3s'
                                          onBlur = {(event) => {
                                              if (event.target.defaultValue.trim().length > 0 ){
                                                  document.getElementById('emailGroup-label').classList.add('isFilled');
                                              } else {
                                                  document.getElementById('emailGroup-label').classList.remove('isFilled');
                                              }
                                          }}/>
                            <InputControl name="phone" label="Nr telefonu" className={'abs phone'} id={'phoneGroup'} transition = 'all 0.3s'
                                          onBlur = {(event) => {
                                              if (event.target.defaultValue.trim().length > 0 ){
                                                  document.getElementById('phoneGroup-label').classList.add('isFilled');
                                              } else {
                                                  document.getElementById('phoneGroup-label').classList.remove('isFilled');
                                              }
                                          }}/>
                            <Box position={'relative'} w={'100%'}>
                                <Box position={'absolute'} left={'-5px'} top={'2px'} color = {'#9BCD66'} fontWeight = {'bold'} ml={'5px'} mr={'5px'} fontSize={'7px'}>■</Box>
                                <TextareaControl name="message" label="Napisz wiadomość" sx={{ 'label': {pl:'10px'}}}/>
                            </Box>
                            <Flex alignItems={'flex-end'}>
                                <Box className="chakra-form-control" role="group" w={'70%'}>
                                    <Box display={'flex'} alignItems={'center'}>
                                        <Field  type="checkbox" color={'green'} name="agreement"  className="form-check-input"  id="agreement" />
                                        <label htmlFor="agreement" style={{ fontSize: '11px', display: 'flex', alignItems: 'center', flexWrap: 'wrap', fontWeight: 'normal' }}>
                                            <Box as={'span'} color = {'#9BCD66'} fontWeight = {'bold'} ml={'5px'} mr={'5px'} fontSize={'7px'}>■</Box>
                                            Zapoznałem/am się z&nbsp;<a href="/polityka-prywatnosci/" title='Polityka Prywatności'> Polityką Prywatności</a> oraz klauzulą informacyjną&nbsp;<a href='/rodo/' title={'RODO'}> RODO</a></label>
                                    </Box>
                                    <ErrorMessage style={{fontSize:'11px', color:'#E53E3E'}} id="agreementGroup-feedback" name="agreement" component="div" className="chakra-form__error-message" />
                                </Box>
                                <Flex w={'30%'} fontSize={'11px'} justifyContent={'flex-end'}>
                                    <Box color = {'#9BCD66'} fontWeight = {'bold'} ml={'5px'} mr={'5px'} fontSize={'7px'}>■</Box>
                                    Pola wymagane
                                </Flex>
                            </Flex>
                            <ButtonGroup>
                                <SubmitButton bg='#008F47'
                                              borderRadius='0'
                                              color='white'
                                              w='360px'
                                              p='20px 30px 20px 30px'
                                              h='auto'
                                              fontSize='13px'
                                              fontWeight='bold'
                                              textTransform='uppercase'
                                              letterSpacing = '2px'
                                              position = 'relative'
                                              justifyContent='flex-start'
                                              transition = 'all 0.3s'
                                              textAlign='left'
                                              m={{base:'50px auto',md:'55px 0 80px 0'}}
                                              display={{base: 'block', md: 'flex'}}
                                              _active={{
                                                  textDecoration: 'none',
                                                  bg: '#008F47'
                                              }}
                                              _focus={{
                                                  textDecoration: 'none',
                                                  bg: '#008F47'
                                              }}
                                              _hover={{
                                                  textDecoration: 'none',
                                                  bg: '#008F47'
                                              }}
                                              sx = {{
                                                  '&:hover img' : {
                                                      right:'15px'
                                                  }
                                              }}>
                                    Wyślij wiadomość
                                    <Img src={arrow} alt='Arrow right' w={'30px'} position={'absolute'} top={'40%'} right={'45px'} transition = 'all 0.3s'/>
                                </SubmitButton>
                            </ButtonGroup>
                        </VStack>
                    </Box>
                )}
            </Formik>
    );
};

export default ContactForm;